import React from 'react';
import './ServiceCard.css';
// import bannerImg from "../../images/service-banner-img.svg"
import cardImg from '../../../images/service-banner-img.svg';

const ServiceCard = ({ serv, cn }) => {

    const { title, description, image_url } = serv;

    return (
        <div className="row d-flex align-items-center py-3" style={{ flexDirection: cn }}>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-3">
                <h1 className='service-card-title'>{title}</h1>
                <p className='service-card-desc-txt'>{description}</p>

            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-5 ">
                <img src={image_url} alt="" className='service-card-img' />
            </div>

        </div>
    );
};

export default ServiceCard;