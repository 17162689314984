import React, { useEffect, useState } from 'react';
import './DetailsBlog.css';
import bannerImg from "../../../images/details-blog-banner-img.png";
import BusinessContact from "../../Shared/BusinessContact/BusinessContact";
import Navigation from "../../Shared/Navigation/Navigation";
import Footer from "../../Shared/Footer/Footer";
import { useParams } from 'react-router';
import { Triangle } from 'react-loader-spinner';
import parse from 'html-react-parser';
import moment from 'moment';
import PostCard from "../AllPosts/PostCard/PostCard"

const DetailsBlog = () => {
    const { news_slug } = useParams();

    const [newses, setNewses] = useState({});
    const [news, setNews] = useState([]);

    let day = moment(newses?.updated_at);

    //is loading state
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        fetch(`https://danapuristaging.techsistltd.com/news_details/${news_slug}/`)
            .then(res => res.json())
            .then(data => {
                setNewses(data.data)
                // setNews(data.data)
            })
            .finally(() => setIsLoading(false))
    }, [news_slug])


    if (isLoading) {
        return <div className="d-flex align-items-center justify-content-center vh-100" >
            <Triangle ariaLabel="loading-indicator" />
            Please wait...
        </div>
    }

    return (
        <>

            <Navigation></Navigation>
            <div className='container-fluid py-5'>
                <div className="container ">
                    <h1 className='details-blog-top-title'>{newses?.title}</h1>
                    {/* <p className='details-blog-top-sub-title'>Created By: {newses?.created_by}</p> */}
                    <p className='details-blog-top-date'>{day.format("LL")}</p>
                    <img src={newses?.image_url} alt="" className='img-fluid' />

                    <div className="blog-description-container pt-5">
                        {/* <h1 className='blog-description-title'>{blogs?.title}</h1> */}
                        <p className='blog-description-details-txt'>{parse(newses?.description)}</p>

                    </div>
                    {/* <div className="see-more-post py-5">
                        <h1 className='pb-4'>Read more posts</h1>

                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 pt-5">
                            {news?.blog_list_qs?.map(blog => <PostCard key={blog.id} blog={blog}></PostCard>)}

                        </div>
                    </div> */}

                </div>
            </div>

            <BusinessContact></BusinessContact>
            <Footer></Footer>
        </>
    );
};

export default DetailsBlog;