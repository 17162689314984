import React from 'react';
import ATeamCard from '../ATeamCard/ATeamCard';
import './ATeam.css';

const ATeam = ({ about }) => {

    return (
        <div className='container-fluid pt-5 '>
            <div className="container pb-5 pt-5">
                <h2 className='a-team-holder-title'>{about?.team_header?.title}</h2>
                <p className='a-team-holder-desc'>{about?.team_header?.description}</p>
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-4 py-4 px-4 a-team-card-holder">

                    {about?.team_header?.team_details.map((teamMember, index) => <ATeamCard
                        teamMember={teamMember}
                        key={teamMember.id}
                    ></ATeamCard>)}


                </div>
            </div>
        </div>
    );
};

export default ATeam;