import React from 'react';
import './ATeamCard.css';
import { FaFacebookF, FaLinkedin, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";

const ATeamCard = ({ teamMember }) => {
    // console.log(teamMember)

    return (
        <div className="col">
            <div className="a-team-card ">
                <div className="row  align-items-center">

                    <div className="col-12 col-sm-12 col-lg-6 col-md-12 a-team-card-left">
                        <div className="a-team-card-img">
                            <img src={teamMember?.image_url} className="img-fluid " alt="..." />
                        </div>
                        {/* <div className="biography">
                            
                            <p className='a-team-bio-details'>{teamMember?.description}</p>
                        </div> */}
                    </div>

                    <div className="col-12 col-sm-12 col-lg-6 col-md-12 a-team-card-right">
                        <div className="a-team-card-all-txt d-flex align-items-center justify-content-center flex-column">
                            <h5 className="a-team-card-title">{teamMember?.name}</h5>
                            <h6 className="a-team-card-degis">{teamMember?.designation}</h6>
                            {/* <p className="a-team-card-bio">{teamMember?.description}</p> */}


                        </div>
                        <div className="biography" role={"button"}>
                            {/* <h5 className='a-team-bio-title'>Biography</h5> */}
                            <p className='a-team-bio-details '>{teamMember?.description}</p>
                        </div>

                        {/* <div className='d-flex justify-content-center align-items-center p-2'>

                            <a href={teamMember?.facebook_url} target="_blank" rel="noreferrer" >
                                <div className='a-team-social-icon-holder'>
                                    <FaFacebookF className=' a-team-social-icon' >
                                    </FaFacebookF>
                                </div>
                            </a>
                            <a href={teamMember?.linkedin_url} target="_blank" rel="noreferrer" >
                                <div className='a-team-social-icon-holder'>
                                    <FaLinkedin className=' a-team-social-icon' ></FaLinkedin>
                                </div>
                            </a>
                            <a href={teamMember?.twitter_url} target="_blank" rel="noreferrer" >
                                <div className='a-team-social-icon-holder'>
                                    <FaTwitter className=' a-team-social-icon' ></FaTwitter>
                                </div>

                            </a>
                        </div> */}
                    </div>
                </div>
            </div >
        </div>
    );
};

export default ATeamCard;