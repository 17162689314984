import React from 'react';
import "./HomeTopNewsCard.css";
import HTMLReactParser from 'html-react-parser';
import moment from "moment";
import newsPerson from '../../../images/news-post-person-img.svg';
import { Link } from 'react-router-dom';

const HomeTopNewsCard = ({ showNews }) => {

    let day = moment(showNews?.created_at);
    return (
        <div className="col">
            <div className=" h-100 text-start archives-news-card">
                <img src={showNews?.image_url} className="card-img-top" alt="..." />
                <div className="card-body">
                    {/* <div className="news-post-user-info py-3">
                        <img src={newsPerson} alt="" className='img-fluid rounded-circle' width={"32"} />
                        <h6 className='text-capitalize'>{showNews?.created_by}</h6>
                        <p className=''>{day.format("LL")}</p>
                    </div> */}
                    <h4 className="archives-news-card-title">{showNews?.title}</h4>
                    {/* <p className="archives-news-card-sub">{HTMLReactParser(showNews?.description?.slice(0, 100) + "...")}</p> */}
                </div>
                <div>
                    <Link to={`/news/${showNews?.slug}`}>
                        <button className='archives-read-more-btn'>Read More</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default HomeTopNewsCard;