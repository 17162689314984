import React from 'react';
import { useEffect, useState } from 'react';
import CorporateValueCard from '../../AboutUs/CorporateValueCard/CorporateValueCard';
import CoreValueCard from './CoreValueCard/CoreValueCard';
import "./CoreValues.css";


const CoreValues = ({ homeInfo }) => {


    return (


        <div className="container-fluid py-5 text-start">
            <div className="container overflow-hidden ">
                <div className=''>
                    <h2 className=' text-center coreValues-title '>{homeInfo?.core_value?.title}</h2>
                </div>
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3  g-4">
                    {
                        homeInfo?.core_value?.core_value_details?.map(corValue => <CoreValueCard

                            corValue={corValue}
                            key={corValue.id}
                        >

                        </CoreValueCard>)
                    }

                </div>
            </div >
        </div>

    );
};

export default CoreValues;