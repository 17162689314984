import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import './BusinessContact.css';

const BusinessContact = ({ blogs }) => {
    const navigate = useNavigate();
    const [contact, setContact] = useState({});
    // console.log(contact)

    useEffect(() => {
        fetch("https://danapuristaging.techsistltd.com/home_details/")
            .then(res => res.json())
            .then(data => setContact(data?.data?.contact))
    }, [])

    return (
        <div className='container-fluid py-5 bussiness-contact-container '>
            <div className='py-5  container'>
                <h1 className='business-contact-title'>{contact?.title}</h1>
                <p className='business-contact-desc-txt'>{contact?.description}</p>
                <button className='contact-us-btn' onClick={() => navigate("/contact")}>{contact?.button_text}</button>
            </div>
        </div>
    );
};

export default BusinessContact;