import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import './Navigation.css';
import logo from '../../../images/danapuri-logo.svg';
import { Link } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';

const Navigation = () => {

    const [homeInfo, setHomeInfo] = useState([]);


    //is loading state
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {

        fetch('https://danapuristaging.techsistltd.com/home_details/')
            .then(res => res.json())
            .then(data => {
                setHomeInfo(data.data)
            })

    }, [])


    return (
        <Navbar collapseOnSelect expand="lg" bg="" variant="light" className=' nav-header-link-text '>
            <Container>
                <Navbar.Brand to="/home" as={Link}>
                    <img src={homeInfo?.logo_url} alt="" className='img-fluid' width={"210"} height={"50"} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto text-dark ">
                        <Nav.Link to="/home" as={Link} className="pe-sm-3 text-dark">Home</Nav.Link>

                        <NavDropdown title="Solutions & Services" id="collasible-nav-dropdown" className="pe-sm-3 text-dark" style={{ color: "black" }}>

                            {
                                homeInfo?.danapuri_offer?.danapuri_offer_details?.map((navItem) => <NavDropdown.Item
                                    key={navItem.id}
                                    className='text-dark'
                                    as={Link} to={`/services/${navItem?.slug}`}>
                                    {navItem?.title}
                                </NavDropdown.Item>)
                            }


                        </NavDropdown>
                        <Nav.Link to="/archives" as={Link} className="pe-sm-3 text-dark">Danapuri Archives</Nav.Link>
                        <Nav.Link to="/aboutUs" as={Link} className="pe-sm-3 text-dark">This is Danapuri</Nav.Link>
                        <Nav.Link to="/contact" as={Link} className="pe-sm-3 text-dark">Contact Us</Nav.Link>
                    </Nav>

                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;