import React, { useEffect, useState } from 'react';
import "./DanapuriArcivesHome.css";
import DanapuriArchiveNewsCard from './DanapuriArchiveNewsCard/DanapuriArchiveNewsCard';
import { Triangle } from 'react-loader-spinner';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Grid, Navigation, Pagination } from "swiper";


const DanapuriArcivesHome = () => {

    const [archive, setArchive] = useState({});
    const [archiveDetails, setArchiveDetails] = useState([]);
    const [archiveNews, setArchiveNews] = useState([]);

    //is loading state
    const [isLoading, setIsLoading] = useState(true);

    const [selected, setSelected] = useState("active-color");

    const [newsTitle, setTitle] = useState("");

    // archives news filter
    const archiveNewsFillter = (arc_title) => {
        const arcNews = archiveDetails.filter(arc => arc_title === arc.title)
        setArchiveNews(arcNews);
        setTitle(arc_title);
    }

    useEffect(() => {
        setIsLoading(true);
        fetch('https://danapuristaging.techsistltd.com/home_details/')
            .then(res => res.json())
            .then(data => {
                setArchive(data?.data?.archive);
                setArchiveDetails(data?.data?.archive?.archive_country_description);
            })
            .finally(() => setIsLoading(false))
    }, [])
    if (isLoading) {
        return <div className="d-flex align-items-center justify-content-center vh-100" >
            <Triangle ariaLabel="loading-indicator" />
            Please wait...
        </div>
    }
    return (

        <div className="container-fluid py-5 danapuri-archives-home">
            <div className='container py-5'>
                <h6 className='archive-country-title '>{archive?.title}</h6>

                <div className='row   justify-content-between py-5'>

                    <div className='col-12 col-sm-12 col-lg-3 col-md-4 text-start'>


                        {
                            archiveDetails?.map(detailsArchive => <li

                                key={detailsArchive.id}
                                className={`archive-country-li  
                            ${detailsArchive?.title === newsTitle && selected}`
                                }

                                onClick={() => {
                                    archiveNewsFillter(detailsArchive?.title);


                                }}
                            >{detailsArchive?.title}</li>)
                        }

                    </div>

                    <div className='col-12 col-sm-12 col-lg-9 col-md-8 text-start'>

                        {/* <div className='row row-cols-1  row-cols-lg-2 row-cols-lg-2 g-4  '>

                            {archiveNews.length === 0 ? archiveDetails[0]?.archive_description.map(newsCC => {

                                return (<DanapuriArchiveNewsCard
                                    key={newsCC.id}
                                    newsC={newsCC}
                                ></DanapuriArchiveNewsCard>)
                            }) :

                                archiveNews?.map(news => {
                                    return news?.archive_description.map((newsC => (<DanapuriArchiveNewsCard
                                        key={newsC.id}
                                        newsC={newsC}
                                    ></DanapuriArchiveNewsCard>)))
                                })
                            }

                        </div> */}
                        <>

                            <Swiper
                                slidesPerView={2}
                                spaceBetween={30}
                                slidesPerGroup={3}
                                // loop={true}
                                // loopFillGroupWithBlank={true}
                                pagination={{
                                    clickable: true,
                                }}
                                Navigation={{
                                    clickable: true,
                                }}

                                navigation={{
                                    clickable: true,
                                }}
                                modules={[Pagination, Navigation]}
                                className="mySwiper"
                            >
                                {
                                    archiveNews.length === 0 ? archiveDetails[0]?.archive_description.map(newsCC => {

                                        return (<SwiperSlide key={newsCC.id}><DanapuriArchiveNewsCard
                                            key={newsCC.id}
                                            newsC={newsCC}
                                        ></DanapuriArchiveNewsCard></SwiperSlide>)
                                    }) :
                                        archiveNews?.map(news => {
                                            return news?.archive_description.map((newsC => (<SwiperSlide
                                                key={newsC.id}>
                                                <DanapuriArchiveNewsCard
                                                    key={newsC.id}
                                                    newsC={newsC}
                                                ></DanapuriArchiveNewsCard>
                                            </SwiperSlide>)))
                                        })
                                }
                            </Swiper>
                        </>



                    </div>

                </div>
            </div>
        </div >


    );
};

export default DanapuriArcivesHome;


