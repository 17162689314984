import React from 'react';
import './CorporateValueCard.css';
import corporateIcon from "../../../images/corporate-value-card.svg";

const CorporateValueCard = ({ corporateV }) => {
    return (
        <div className="col">
            <div className="corporate-card h-100  text-start border-0">
                <div className=''>
                    <img src={corporateV?.icon_url} className="img-fluid  ms-3 mt-3 corporate-value-icon" alt="..." width="40" height="40" />
                </div>
                <div className="card-body">
                    <h4 className="corporate-value-card-title">{corporateV?.title}</h4>
                    <p className="corporate-value-desc-text">{corporateV?.description}</p>
                </div>
            </div>
        </div>
    );
};

export default CorporateValueCard;