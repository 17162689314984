import './App.css';
import { Routes, Route } from "react-router-dom";
import Blog from './Pages/Blog/Blog';
import Contact from './Pages/Contact/Contact';
import Services from './Pages/Services/Services';
import AboutUs from './Pages/AboutUs/AboutUs';
import Archives from './Pages/Archives/Archives';
import Home from './Pages/Home/Home';
import DetailsBlog from './Pages/Blog/DetailsBlog/DetailsBlog';


function App() {
  return (
    <div className="App app-body">

      {/* all route of danapuri */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        {/* <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blog_slug" element={<DetailsBlog />} /> */}
        <Route path="/news/:news_slug" element={<DetailsBlog />} />
        <Route path="/services/:service_slug" element={<Services />} />
        <Route path="/services" element={<Services />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/archives" element={<Archives />} />
      </Routes>
    </div>
  );
}

export default App;
