import React, { useState } from 'react';
import './ReadmoreWhyDanapuri.css';

const ReadmoreWhyDanapuri = ({ children }) => {
    const [isReadMore, setIsReadMore] = useState(false);

    const toggleHandler = () => {
        setIsReadMore(prvStat => !prvStat)
    }

    return (
        <div className='whyDanapuri-description'>
            {isReadMore ? children : children.substring(0, 300)}
            <button className='read-more-less-btn' onClick={() => toggleHandler()}>{isReadMore ? "Read less" : "Read More"}</button>
        </div>
    );
};

export default ReadmoreWhyDanapuri;