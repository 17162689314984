import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import logo from '../../../images/danapuri-logo.svg';
import { FaFacebook, FaLinkedin, FaTwitter, FaInstagram } from "react-icons/fa";
import axios from 'axios';
import Swal from 'sweetalert2';
import { Triangle } from 'react-loader-spinner';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    // success toast-sweetalert
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    const [footerInfo, setFooterInfo] = useState([]);

    //is loading state
    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState("");

    const newsLetterHandler = () => {

        axios.post('https://danapuristaging.techsistltd.com/subscribe_news_letter/', {
            email: email

        })
            .then(function (response) {
                // console.log(response)
                if (response.data.status) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Email Submited!!'
                    })
                    setEmail("");
                }
                else {
                    alert(response.data.msg);

                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    useEffect(() => {
        setIsLoading(true);
        fetch('https://danapuristaging.techsistltd.com/home_details/')
            .then(res => res.json())
            .then(data => {
                setFooterInfo(data.data)
            })
            .finally(() => setIsLoading(false))
    }, [])
    if (isLoading) {
        return <div className="d-flex align-items-center justify-content-center vh-100" >
            <Triangle ariaLabel="loading-indicator" />
            Please wait...
        </div>
    }
    return (
        <div className='container-fluid footer-segment'>

            <div className="container mx-auto p-0 py-4">
                <div className="row mx-auto  justify-content-center">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <p className='footer-title-txt'>Company</p>
                        <ul className='list-unstyled text-start'>

                            <li className="footer-li"> <Link to="/aboutUs" className='footer-title-link '>About Us</Link></li>

                            <li className="footer-li"> <HashLink smooth to="/home#danapuriOffer" className='footer-title-link'>
                                Services
                            </HashLink></li>


                            <li className="footer-li"> <a href={footerInfo.file_url} target={"_blank"} rel="noreferrer" className='footer-title-link'>Download</a></li>

                            {/* <a href={footerInfo.file_url} target={"_blank"} rel="noreferrer"><li className="footer-li">Download</li> </a> */}
                            {/* <a href={newsButton.file_url} target={"_blank"} rel="noreferrer"> */}

                            {/* </a> */}
                            {/* <li className="footer-li"> <Link to="" className='footer-title-link'>Testimonial</Link></li> */}


                        </ul>
                    </div>
                    {/* <div className="col-12 col-sm-12 col-md-6 col-lg-2">
                        <p className='footer-title-txt'>Company</p>
                        <ul className='list-unstyled text-start'>

                            <li className="footer-li"> <Link to="" className='footer-title-link '>About Us</Link></li>
                            <li className="footer-li"> <Link to="" className='footer-title-link'>Why Choose us</Link></li>
                            <li className="footer-li"> <Link to="" className='footer-title-link'>Services</Link></li>
                            <li className="footer-li"> <Link to="" className='footer-title-link'>Testimonial</Link></li>


                        </ul>
                    </div> */}
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <p className='footer-title-txt'>Resources</p>
                        <ul className='list-unstyled text-start'>

                            <li className="footer-li"> <Link to="" className='footer-title-link'>Privacy Policy</Link></li>
                            <li className="footer-li"> <Link to="" className='footer-title-link'>Terms and Condition</Link></li>

                            <li className="footer-li"> <Link to="/contact" className='footer-title-link'>Contact Us</Link></li>

                        </ul>
                    </div>
                    {/* <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <p className='footer-title-txt'>Product</p>
                        <ul className='list-unstyled text-start'>

                            <li className="footer-li"> <Link to="" className='footer-title-link'>Project managment</Link></li>
                            <li className="footer-li"> <Link to="" className='footer-title-link'>Time tracker</Link></li>
                            <li className="footer-li"> <Link to="" className='footer-title-link'>Time schedule</Link></li>
                            <li className="footer-li"> <Link to="" className='footer-title-link'>Lead generate</Link></li>
                            <li className="footer-li"> <Link to="" className='footer-title-link'>Remote Collaboration</Link></li>


                        </ul>
                    </div> */}
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 text-start pt-3  ">
                        <Link to="/home"><img src={footerInfo?.logo_url} alt="" className='img-fluid my-5 pb-3 m-0' width={"210"} height={"50"} /></Link>
                        {/* <p className='footer-title-txt'>Subscribe to our Newsletter</p> */}

                        {/* <div className=''>
                            <input className='subcribe-input ' type="email" placeholder='Enter your Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                            <button className='subscribe-btn mt-2 ' onClick={newsLetterHandler}>Subscribe</button>
                        </div> */}
                    </div>
                </div>
                <div className="row align-items-center pt-3">
                    <div className="col-12 col-sm-12 col-md-3 col-lg-4 ">
                        <hr className='footer-line' />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4  d-flex justify-content-center align-items-center">
                        <a href="https://techsistltd.com/" target={"_blank"} rel="noreferrer" className='text-decoration-none'>
                            <p className='copyright-text'>© Copyright /Techsist Limited 2022</p>
                        </a>
                        <div className='d-flex '>
                            {/* <FaFacebook className='text-secondary social-icon'></FaFacebook> */}
                            <a href={footerInfo?.linkedin_url} target={"_blank"} rel="noreferrer" >
                                <FaLinkedin className='text-secondary social-icon'></FaLinkedin>
                            </a>
                            <a href={footerInfo?.twitter_url} target={"_blank"} rel="noreferrer" >
                                <FaTwitter className='text-secondary social-icon'></FaTwitter>
                            </a>
                            <a href={footerInfo?.instagram_url} target={"_blank"} rel="noreferrer" >
                                <FaInstagram className='text-secondary social-icon'></FaInstagram>
                            </a>




                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-3 col-lg-4 ">
                        <hr className='footer-line' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;