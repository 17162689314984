import React from 'react';
import "./HomeBannerCard.css";
import img11 from "../../../../images/home-banner/l1.png";
import img2 from "../../../../images/home-banner/11 1.png";

const HomeBannerCard = ({ service }) => {


    return (
        <div className="col">
            <div className="home-banner-card h-100">
                <img src={service?.slider_image_url} className="img-fluid" alt="..." width={"480"} />
                <div className='position-absolute'>
                    <img src={service?.icon_url} className="img-fluid home-banner-service-logo" alt="..." width={"160"} />
                    <p className='home-banner-card-title'>{service?.title}</p>
                </div>

            </div>
        </div>
    );
};

export default HomeBannerCard;