import React, { useEffect, useState } from 'react';
import BusinessContact from '../Shared/BusinessContact/BusinessContact';
import Footer from '../Shared/Footer/Footer';
import Navigation from '../Shared/Navigation/Navigation';
import './Archives.css';
import ArchivesBanner from './ArchivesBanner/ArchivesBanner';
import ArchivesIndustrialTrend from './ArchivesIndustrialTrend/ArchivesIndustrialTrend';
import ArchivesLatestBrochures from './ArchivesLatestBrochures/ArchivesLatestBrochures';
import ArchivesLatestNews from './ArchivesLatestNews/ArchivesLatestNews';
import { Triangle } from 'react-loader-spinner';

const Archives = () => {

    const [archives, setArchives] = useState({});
    //is loading state
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        fetch("https://danapuristaging.techsistltd.com/archives_details/")
            .then(res => res.json())
            .then(data => setArchives(data.data))
            .finally(() => setIsLoading(false))
    }, [])

    if (isLoading) {
        return <div className="d-flex align-items-center justify-content-center vh-100" >
            <Triangle ariaLabel="loading-indicator" />
            Please wait...
        </div>
    }

    return (
        <div>
            <Navigation></Navigation>
            <ArchivesBanner archives={archives}></ArchivesBanner>
            <ArchivesLatestNews archives={archives}></ArchivesLatestNews>
            <ArchivesLatestBrochures archives={archives}></ArchivesLatestBrochures>
            {/* <ArchivesIndustrialTrend archives={archives}></ArchivesIndustrialTrend> */}
            <BusinessContact></BusinessContact>
            <Footer></Footer>
        </div>
    );
};

export default Archives;