import React, { useEffect, useState } from 'react';
import './DanapuriOffer.css';
import DanapuriOfferCard from './DanapuriOfferCard/DanapuriOfferCard';

const DanapuriOffer = ({ homeInfo }) => {
    return (
        <div className='container-fluid dhanaPuriOffers-bg  py-5' id="danapuriOffer">
            <div className="container overflow-hidden ">
                <h2 className='dhanapuriOffers-title text-center '>{homeInfo?.danapuri_offer?.title}</h2>
                <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4  g-4 danapuri0ffers-margin">
                    {
                        homeInfo?.danapuri_offer?.danapuri_offer_details?.map(offers => <DanapuriOfferCard
                            offers={offers}
                            key={offers.id}


                        ></DanapuriOfferCard>)
                    }

                </div>

            </div>
        </div>


    );
};

export default DanapuriOffer;