import React from 'react';
import CorporateValueCard from '../CorporateValueCard/CorporateValueCard';
import './CorporateValues.css';

const CorporateValues = ({ about }) => {
    // const { title, description, sub_description } = about?.corporate_value;
    // console.log(about?.corporate_value?.corporate_value_details)
    return (
        <div className='container-fluid py-5 corporate-val-container'>
            <div className="container mx-auto py-5">
                <h2 className='corporate-value-title'>{about?.corporate_value?.title}</h2>
                <p className='corporate-value-sub-title'>{about?.corporate_value?.sub_description}</p>

                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-4 py-4 px-4 corporate-card-holder">
                    {about?.corporate_value?.corporate_value_details?.map((corporateV, index) => <CorporateValueCard
                        corporateV={corporateV}
                        key={corporateV.id}
                    ></CorporateValueCard>)}


                </div>
            </div>

        </div>
    );
};

export default CorporateValues;