import React, { useEffect, useState } from 'react';
import './Services.css';
import bannerImg from "../../images/service-banner-img.svg";
import ServiceCard from './ServiceCard/ServiceCard';
import Footer from '../Shared/Footer/Footer';
import BusinessContact from '../Shared/BusinessContact/BusinessContact';
import Navigation from '../Shared/Navigation/Navigation';
import { useNavigate, useParams } from 'react-router';
import { Triangle } from 'react-loader-spinner';
import bannerBg from "../../images/banner-bg-new.png"

const Services = () => {


    const { service_slug } = useParams();
    const navigate = useNavigate();
    const [service, setService] = useState([]);
    // console.log(service)

    const style = {
        width: "100%",
        height: "500px",
        backgroundImage: `url(${service
            .image_url})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        // Adding media query..
        ' @media only screen and (maxWidth: 767px)': {
            height: "auto",
            width: "100%"
        },
    };
    //is loading state
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        setIsLoading(true);
        fetch(`https://danapuristaging.techsistltd.com/service_details/${service_slug}/`)
            .then(res => res.json())
            .then(data => setService(data?.data))
            .finally(() => setIsLoading(false))

    }, [service_slug])

    if (isLoading) {
        return <div className="d-flex align-items-center justify-content-center vh-100" >
            <Triangle ariaLabel="loading-indicator" />
            Please wait...
        </div>
    }


    return (
        <>
            <Navigation></Navigation>
            <div className='container-fluid py-5 p-0 service-banner-container ' style={style}>
                <div className="container py-5">

                    {/* service banner section  */}
                    <div className="row d-flex align-items-center " >

                        <div className="col-12 col-sm-12 col-md-12 col-lg-8 p-2">
                            <h1 className='service-banner-title'>{service?.title}</h1>
                            <p className='service-banner-desc-txt'>{service?.description}</p>
                            <div className='text-start'>
                                {/* <button className='getting-started-btn'>{service?.button_text1}</button> */}
                                {/* <button className='contact-service-btn' onClick={() => navigate("/contact")}>{service?.button_text2}</button> */}
                                <button className='getting-started-btn' onClick={() => navigate("/contact")}>{service?.button_text2}</button>
                            </div>
                        </div>

                        {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-2">
                            <img src={service?.image_url} alt="" className='service-banner-img' />
                        </div> */}

                    </div>
                </div>
            </div>

            <div className="container-fluid p-0 services-sub-container py-5">
                {/* service all  */}
                <div className=' container py-5'>
                    {service?.service_details?.map((d_service, index) => {

                        return <div key={d_service?.id}>
                            <h2 className='service-all-header-title'>{d_service?.title}</h2>
                            {/* <p className='service-card-desc-txt ' >{d_service?.description}</p> */}
                            <div>
                                {d_service?.service_description?.map((serv, index) => <ServiceCard
                                    cn={index % 2 === 0 ? 'row-reverse' : ""}
                                    serv={serv}
                                    key={serv.id}
                                >

                                </ServiceCard>)}
                            </div>
                        </div>
                    })}
                </div>

            </div>

            <BusinessContact ></BusinessContact>
            <Footer></Footer>
        </>
    );
};

export default Services;