import React from 'react';
import './CoreValueCard.css';

const CoreValueCard = ({ corValue }) => {
    const { icon_url, title, description } = corValue;

    return (
        <div className="col">

            <div className=' core-values-card h-100'>
                <img className="coreValues-image img-fluid" src={icon_url} alt="" width={"36"} height={"36"} />
                <h2 className='coreValuesCard-title text-center'>{title}</h2>
                <p className='coreValuesCard-description'>{description}</p>
            </div>

        </div>
    );
};

export default CoreValueCard;