import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import "./HomeBanner.css";
import Typewriter from 'typewriter-effect';
import { Triangle } from 'react-loader-spinner';
import bannerImg from "../../../images/cover.jpg";
import bannerImg1 from "../../../images/banner-1.jpg";
import coverLogo from "../../../images/danapuri-cover-logo.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";
import HomeBannerCard from './HomeBannerCard/HomeBannerCard';


const HomeBanner = ({ homeInfo }) => {
    console.log(homeInfo?.slider?.file_url);

    const style1 = {
        backgroundImage: `url(${bannerImg})`,
        backgroundColor: " #cccccc",
        height: "75vh", /* You must set a specified height */
        backgroundPosition: "center", /* Center the image */
        backgroundRepeat: "no-repeat", /* Do not repeat the image */
        backgroundSize: "cover" /* Resize the background image to cover the entire container */


    }
    const style2 = {
        backgroundImage: `url(${bannerImg1})`,
        backgroundColor: " #cccccc",
        height: "524px", /* You must set a specified height */
        backgroundPosition: "center", /* Center the image */
        backgroundRepeat: "no-repeat", /* Do not repeat the image */
        backgroundSize: "cover" /* Resize the background image to cover the entire container */
    }

    return (

        <div className='home-banner container-fluid p-0 ' >
            <>
                <Swiper
                    modules={[EffectFade, Navigation, Pagination, Autoplay]}
                    // spaceBetween={30}
                    effect={"fade"}
                    navigation={true}
                    // pagination={{
                    //     clickable: true,
                    // }}
                    autoplay={{
                        delay: 3600,
                        disableOnInteraction: false,
                    }}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <ReactPlayer

                            className="video-player "
                            url={homeInfo?.slider?.file_url}
                            width={"100%"}
                            height={"100%"}
                            playing={true}
                            loop={true}
                            muted={true}
                            playsinline={true}
                        />
                        {/* <div className="container-fluid banner-img d-flex align-items-center justify-content-center"  >


                            </div> */}
                        {/* <img src={coverLogo} alt="" className='img-fluid ' width={"1100"} /> */}
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container-fluid p-0" >
                            <div className="row row-cols-4 row-cols-md-4  row-cols-lg-4 g-0">
                                {homeInfo?.slider?.sub_slider_image_url?.map((slider_img) => <div className="col" key={slider_img?.id}>

                                    <img src={slider_img?.file_url} className="img-fluid" alt="..." width={"480"} />


                                </div>)}

                            </div>
                            <div className="row row-cols-4 row-cols-md-4 row-cols-lg-4 g-0" >

                                {homeInfo?.slider?.service_image_url?.map((service, index) => <HomeBannerCard
                                    key={service.id}
                                    service={service}

                                ></HomeBannerCard>)}

                            </div>
                        </div>

                    </SwiperSlide>

                </Swiper>
            </>
            {/* <img className='homeBanner-img  ' src={homeInfo?.slider?.file_url} alt="" /> */}
            {/* <div className="container">

                <ReactPlayer
                    height={"75%"}
                    width={"100%"}
                    className="video-player"
                    url={homeInfo?.slider?.file_url}
                    playing={true}
                    loop={true}
                    muted={true}
                    playsinline={true}
                />

                <div className='banner-text p-5'>

                <Typewriter
                    options={{
                        strings: bannerPage.title,
                        autoStart: true,
                        loop: true,
                    }}
                />

            </div>
        </div> */}

        </div >

    );
};

export default HomeBanner;