import React from 'react';
import './ArchiveNewNewsCard.css';
import img from '../../../images/danapuri-archive-img.svg';
import { Link } from 'react-router-dom';

const ArchiveNewNewsCard = ({ d_news }) => {
    return (
        <div className="col">
            <div className=" h-100 text-start archives-new-news-card py-3">
                <img src={d_news?.image_url} className="img-fluid" alt="..." width={"283"} />
                <div className="" style={{ marginTop: "14px", marginBottom: "14px" }}>
                    <h4 className="archives-new-news-card-title">{d_news?.title}
                    </h4>
                </div>
                <div>
                    <Link to={`/news/${d_news?.slug}`}>
                        <button className='read-more-txt-new'>Read More</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ArchiveNewNewsCard;