import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import './Contact.css';
import callIcon from "../../images/contact-icon/call-icon.svg";
import email1Icon from "../../images/contact-icon/email-icon-1.svg";
import email2Icon from "../../images/contact-icon/email-icon-2.svg";
import Footer from '../Shared/Footer/Footer';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaFacebookF, FaLinkedin, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import Navigation from '../Shared/Navigation/Navigation';
import { Triangle } from 'react-loader-spinner';

const Contact = () => {

    // success toast-sweetalert
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const [contact, setContact] = useState([]);
    // console.log(contact)
    const { register, handleSubmit, reset } = useForm();

    //is loading state
    const [isLoading, setIsLoading] = useState(true);

    const [contactTitle, setContactTitle] = useState([])

    const onSubmit = data => {

        axios.post('https://danapuristaging.techsistltd.com/drop_message/', data)
            .then(function (response) {
                // console.log(response);
                if (response.data.status) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Message Send Successfully!!'
                    })
                    reset();
                }
                else {
                    alert(response.data.msg);

                }
            })
            .catch(function (error) {
                console.log(error);
            });


    };

    useEffect(() => {
        setIsLoading(true);
        fetch('https://danapuristaging.techsistltd.com/home_details/')
            .then(res => res.json())
            .then(data => {
                setContact(data.data)
                setContactTitle(data.data.contact)

            })
            .finally(() => setIsLoading(false))
    }, [])
    if (isLoading) {
        return <div className="d-flex align-items-center justify-content-center vh-100" >
            <Triangle ariaLabel="loading-indicator" />
            Please wait...
        </div>
    }


    return (
        <>
            <Navigation></Navigation>
            <div className='container-fluid py-5'>
                <div className="container p-0 mx-auto">
                    <h1 className='contact-us-header-txt'>Get in touch with us</h1>

                    <div className="row gx-0 contact-details-div">
                        <h3>Drop us a message</h3>
                        <p>We will get back to you as soon as possible.</p>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-7 p-2">

                            <div className='contact-us-form'>
                                <form className="row g-3" onSubmit={handleSubmit(onSubmit)} >
                                    <div className="col-md-6">
                                        <div className="form-floating ">
                                            <input type="text" className="form-control" id="fullName" placeholder="fullName" required
                                                {...register("name")}
                                            />
                                            <label htmlFor="fullName">Full Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating ">
                                            <input type="text" className="form-control" id="company_name" placeholder="company_name" required
                                                {...register("company_name")}
                                            />
                                            <label htmlFor="company_name">Company Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-floating ">
                                            <input type="text" className="form-control" id="workEmail" placeholder="workEmail" required
                                                {...register("email")}
                                            />
                                            <label htmlFor="workEmail">Work Email</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-floating ">
                                            <input type="text" className="form-control" id="phone" placeholder="subject" required
                                                {...register("phone")}
                                            />
                                            <label htmlFor="phone">Phone</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-floating ">
                                            <textarea type="text" className="form-control border-0" id="message" placeholder="message" required
                                                {...register("message")}
                                                style={{ height: "120px" }}
                                            />
                                            <label htmlFor="message">Message</label>
                                        </div>
                                    </div>


                                    <div className="col-12">
                                        <button type="submit" className="send-btn"

                                        >Send</button>
                                    </div>

                                </form>
                            </div>

                        </div>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-5 p-3">
                            <div className='pb-3 px-lg-3'>

                                {/* <img src={map} alt="" className='img-fluid px-4 pb-3' /> */}
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.7113590151093!2d101.66398211497854!3d3.1705095539045733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc485e123efd9f%3A0x7b0b0d21edf198cc!2s1%2C%20Jalan%20Dutamas%201%2C%20Solaris%20Dutamas%2C%2050480%20Kuala%20Lumpur%2C%20Wilayah%20Persekutuan%20Kuala%20Lumpur!5e0!3m2!1sen!2smy!4v1578201761416!5m2!1sen!2smy" height="320" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='map-iframe' title='map'></iframe>
                            </div>
                            <div className="contact-info-holder  py-3 px-lg-3 ">
                                <div className="contact-info">
                                    <div>
                                        <img src={callIcon} alt="" className='img-fluid' />
                                    </div>
                                    <div>
                                        <h4 className='m-0 '>{contact?.phone}</h4>
                                        <p className='m-0'>Free support</p>
                                    </div>
                                </div>
                                {/* <div className="contact-info">
                                    <div>
                                        <img src={email1Icon} alt="" className='img-fluid' />
                                    </div>
                                    <div>
                                        <h4 className='m-0 '>{contact?.email}</h4>
                                        <p className='m-0'>Help Email support</p>
                                    </div>
                                </div>
                                <div className="contact-info">
                                    <div>
                                        <img src={email2Icon} alt="" className='img-fluid' />
                                    </div>
                                    <div>
                                        <h4 className='m-0 '>{contact?.email}</h4>
                                        <p className='m-0'>Sales Enquiry</p>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="follow-us  py-3 px-lg-3">
                                <h5 className='follow-us-title'>Follow Us On</h5>
                                <p className='follow-us-desc-txt'>Proin eget tortor risus. Donec sollicitudin molestie malesuada. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                <div className='d-flex pt-3 '>

                                    <FaLinkedin className=' contact-us-social-icon'></FaLinkedin>
                                    <FaTwitter className=' contact-us-social-icon'></FaTwitter>
                                    <FaInstagram className=' contact-us-social-icon'></FaInstagram>
                                    <FaYoutube className=' contact-us-social-icon'></FaYoutube>

                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

            </div>
            {/* <BusinessContact></BusinessContact> */}
            {/* <div className='container-fluid py-5 bussiness-contact-container '>
                <div className='py-3'>
                    <h1 className='business-contact-title'>{contactTitle.title}</h1>
                    <p className='business-contact-desc-txt'>{contactTitle?.description}
                    </p>
                    <div className="py-5 ">
                        <a href="/" target="_blank" rel="noreferrer" >
                            <button className='getting-started-btn'>{contactTitle?.button_text}</button>
                        </a>
                    </div>
                    <button className='contact-us-btn'>Contact US</button>
                </div>
            </div> */}
            <Footer></Footer>
        </>
    );
};

export default Contact;