import React from 'react';
import "./DigitalEconomy.css";
import { useEffect, useState } from 'react';

const DigitalEconomy = ({ homeInfo }) => {
    // console.log(homeInfo)

    return (
        <div className='container-fluid digital-background py-5'>

            <div className='digital-content container py-5'>
                <div className='row   align-items-center justify-content-center '>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-8 '>
                        <h2 className='digital-header py-3 '>{homeInfo?.digital_economy?.title}</h2>
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-4 '>


                        <a href={homeInfo?.digital_economy?.file_url} target={"_blank"} rel="noreferrer" >
                            <button className='digital-button border bg-black p-2 text-white'>{homeInfo?.digital_economy?.button_text}</button>
                        </a>

                    </div>
                </div>


            </div>
        </div>

    );
};

export default DigitalEconomy;