import React from 'react';
import { Link } from "react-router-dom";

const DanapuriOfferCard = ({ offers }) => {
    const { icon_url, title, slug } = offers;
    // console.log(offers);
    return (
        <div className="col">
            <Link to={`/services/${slug}`} style={{ textDecoration: "none" }}>
                <div className=' border bg-light dhanapuri-card'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img className='img-offers img-fluid' src={icon_url} alt="" width={"40"} />


                    </div>
                    < hr className='w-25 mx-auto offers-horizontal' id="" style={{ height: "2px" }} />


                    <p id="offers-text" className=' DhanaPuriOffers-description text-center '> 	{title}</p>
                </div>
            </Link>
        </div>
    );
};

export default DanapuriOfferCard;