import React from 'react';
import ArchivesLatestBrochuresCard from '../ArchivesLatestBrochuresCard/ArchivesLatestBrochuresCard';
import './ArchivesLatestBrochures.css';

const ArchivesLatestBrochures = ({ archives }) => {

    const { brochure } = archives;

    return (
        <div className="container-fluid py-5 archive-latest-brochures-container">
            <div className="container">
                <h2 className='archives-latest-news-title'>{brochure?.title}</h2>
                <hr className='archives-latest-news-horizontal-line ' />
                <p className='archives-latest-news-sub-title'>{brochure?.description}</p>

                <div className="archive-latest-brochures-card-container">
                    {brochure?.brochure_details?.map((brochure, index) => <ArchivesLatestBrochuresCard
                        fd={index % 2 === 0 ? 'row-reverse' : ""}
                        brochure={brochure}
                        key={brochure.id}

                    ></ArchivesLatestBrochuresCard>)}


                </div>
            </div>

        </div>
    );
};

export default ArchivesLatestBrochures;