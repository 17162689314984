import React from 'react';
import { useEffect, useState } from 'react';
import HomeTopNewsCard from '../HomeTopNewsCard/HomeTopNewsCard';
import "./HomeTopNews.css";
import { Link } from 'react-router-dom';
const HomeTopNews = () => {
    const [news, setNews] = useState([])
    const [topNews, setTopNews] = useState([])

    useEffect(() => {
        fetch('https://danapuristaging.techsistltd.com/home_details/')
            .then(res => res.json())
            .then(data => {
                setNews(data.data.top_news_list)
                setTopNews(data.data.top_news_list.news_details_list)

            })
    }, [])
    return (
        <div className="container-fluid py-5 archive-latest-news-container">
            <div className="container">
                <h2 className='archives-latest-news-title'>{news?.project_title}</h2>
                <hr className='archives-latest-news-horizontal-line ' />
                <p className='archives-latest-news-sub-title'>{news?.description}</p>

                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 py-5 m-0">
                    {
                        topNews.slice(0, 6).map(showNews => <HomeTopNewsCard
                            showNews={showNews}
                            key={showNews.id}


                        ></HomeTopNewsCard>)
                    }



                </div>
                <Link to="/archives"><button className='top-news-download-btn text-center'>Read More</button></Link>
                {/* <a href={newsButton.file_url} target={"_blank"} rel="noreferrer" >
                    <button className='top-news-download-btn text-center'>Read More</button>
                </a> */}



            </div>

        </div>
    );
};

export default HomeTopNews;