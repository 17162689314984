import React from 'react';
import './ArchivesBanner.css';
import bannerImg from "../../../images/banner-bg-new.png";
import { useEffect, useState } from 'react';

const ArchivesBanner = ({ archives }) => {


    // console.log(archives?.title)
    const style = {
        width: "100%",
        height: "auto",
        backgroundImage: `url(${archives
            .file_url})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        // Adding media query..
        ' @media only screen and (maxWidth: 767px)': {
            height: "auto",
            width: "100%"
        },
    };
    return (
        <div className='container-fluid p-0 ' style={style}>
            <div className="archives-banner-img ">
                {/* <img src={bannerImg} alt="" className='img-fluid' /> */}

                <div className="container p-3 ">

                    {/*  banner section  */}
                    <div className="row align-items-center " style={{ height: "450px" }} >

                        <div className="col-12 col-sm-12 col-md-7 col-lg-8 p-2 ">
                            <h1 className='service-banner-title'>{archives?.title}</h1>
                            {/* <p className='service-banner-desc-txt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum reprehenderit eligendi dolore, expedita architecto impedit!</p> */}
                        </div>



                    </div>
                </div>

            </div>

        </div>
    );
};

export default ArchivesBanner;