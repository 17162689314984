import React from 'react';
import ArchivesCountryCard from '../ArchivesCountryCard/ArchivesCountryCard';
import ArchivesLatestNewsCard from '../ArchivesLatestNewCard/ArchivesLatestNewsCard';
import './ArchivesLatesNews.css';

const ArchivesLatestNews = ({ archives }) => {
    const { project_details } = archives;
    return (
        <div className="container-fluid py-5 archive-latest-news-container">
            <div className="container">
                <h2 className='archives-latest-news-title'>{project_details?.title}</h2>

                <hr className='archives-latest-news-horizontal-line ' />
                <p className='archives-latest-news-sub-title'>{project_details?.description}</p>

                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 py-5 m-0">

                    {project_details?.project_country_list?.map((news, index) => <ArchivesCountryCard
                        news={news}
                        key={news.id}
                    ></ArchivesCountryCard>)}

                </div>
            </div>

        </div>
    );
};

export default ArchivesLatestNews;