import React from 'react';
import './ArchivesLatestBrochuresCard.css';
import brochuresImg from "../../../images/Image.png";

const ArchivesLatestBrochuresCard = ({ fd, brochure }) => {
    return (
        <div className="row d-flex  py-3 align-items-center" style={{ flexDirection: fd }}>

            <div className="col-12 col-sm-12 col-md-6 col-lg-7 p-4 text-start gx-4">
                <h1 className='brochures-card-title'>{brochure?.title}</h1>
                <p className='brochures-card-desc-txt'>{brochure?.description}</p>
                <a href={brochure?.file_url} target={"_blank"} rel="noreferrer" >
                    <button className='brochures-card-download-btn'>{brochure?.button_text}</button>
                </a>

            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-5 p-4 ">
                <img src={brochure?.image_url} alt="" className='brochures-card-img' width={"358"} />
            </div>

        </div>
    );
};

export default ArchivesLatestBrochuresCard;