import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import avatar from "../../../images/men-avatar.png";
import ReadmoreWhyDanapuri from './ReadmoreWhyDanapuri/ReadmoreWhyDanapuri';
import "./WhyDanapuri.css";


const WhyDanapuri = ({ homeInfo }) => {


    return (


        <div className='container-fluid py-5'  >
            <div className='container  position-relative mx-auto text-start '
                style={{
                    height: 665,
                    backgroundImage: `url(${homeInfo?.about_danapuri?.image_url})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }}>
                {/* <img className='' src={whyDanapuri.image_url} alt="" /> */}
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-7 col-lg-7'>

                    </div>
                    <div className='col-12 col-sm-12 col-md-5 col-lg-5  whyDanaPuri-card  text-white     '>
                        <h2 className='whyDanapuri-title '>{homeInfo?.about_danapuri?.title}</h2>
                        {/* <p className='whyDanapuri-description '>{homeInfo?.about_danapuri?.description?.slice(0, 300) + "..."}</p>
                        <Link to="/aboutUs"><button className=' button-text '>{homeInfo?.about_danapuri?.button_text}</button></Link> */}
                        <ReadmoreWhyDanapuri >

                            {homeInfo?.about_danapuri?.description}
                        </ReadmoreWhyDanapuri>

                    </div>

                </div>


            </div>

        </div>



    );
};

export default WhyDanapuri;