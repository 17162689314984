import React from 'react';
import './DanapuriNetwork.css';
import logo1 from '../../../images/danapori-network/Logo-1.svg';
import logo2 from '../../../images/danapori-network/Logo-2.svg';
import logo3 from '../../../images/danapori-network/Logo-3.svg';
import logo4 from '../../../images/danapori-network/Logo-4.svg';
import logo5 from '../../../images/danapori-network/Logo-5.svg';

const DanapuriNetwork = ({ about }) => {
    // console.log(about?.network?.network_details)
    return (
        <div className='container-fluid py-5 danapuri-network-segment'>
            <div className="container ">
                <h2 className="danapuri-network-title">{about?.network?.title}</h2>
                <p className="danapuri-network-sub-desc pb-5 m-0">{about?.network?.short_description}</p>

                {/* client profile  */}
                {
                    about?.network?.network_details?.map((networkD, index) => {

                        return <div className="danapuri-client-profile " key={networkD.id}>
                            <h4 className='danapuri-network-all-title'>{networkD?.title}</h4>
                            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-5 g-2 ">
                                {networkD?.client_details?.map((client, index) => {

                                    return <div className="col text-start" key={client.id}>
                                        <img src={client?.logo_url} alt="" className='img-fluid pe-2 py-2' />
                                    </div>

                                })
                                }
                            </div>


                        </div>
                    })
                }

                {/* <div className="danapuri-client-profile ">
                    <h4 className='danapuri-network-all-title'>Client Portfolio</h4>
                    <div className="row row-cols-1 row-cols-md-3 row-cols-md-5 g-2 ">
                        <div className="col">
                            <img src={logo1} alt="" className='img-fluid pe-2' />
                        </div>
                        <div className="col">
                            <img src={logo2} alt="" className='img-fluid pe-2' />
                        </div>
                        <div className="col">
                            <img src={logo3} alt="" className='img-fluid pe-2' />
                        </div>
                        <div className="col">
                            <img src={logo4} alt="" className='img-fluid pe-2' />
                        </div>
                        <div className="col">
                            <img src={logo5} alt="" className='img-fluid pe-2' />
                        </div>

                    </div>
                </div> */}

                {/* Strategic Partners */}
                {/* <div className="danapuri-strategic-partners ">
                    <h4 className='danapuri-network-all-title'>Strategic Partners</h4>
                    <div className="row row-cols-1 row-cols-md-3 row-cols-md-5 g-2 ">
                        <div className="col">
                            <img src={logo1} alt="" className='img-fluid pe-2' />
                        </div>
                        <div className="col">
                            <img src={logo2} alt="" className='img-fluid pe-2' />
                        </div>
                        <div className="col">
                            <img src={logo3} alt="" className='img-fluid pe-2' />
                        </div>
                        <div className="col">
                            <img src={logo4} alt="" className='img-fluid pe-2' />
                        </div>
                        <div className="col">
                            <img src={logo5} alt="" className='img-fluid pe-2' />
                        </div>

                    </div>
                </div> */}

                {/* Public Relations */}
                {/* <div className="danapuri-public-relations ">
                    <h4 className='danapuri-network-all-title'>Public Relations</h4>
                    <div className="row row-cols-1 row-cols-md-3 row-cols-md-5 g-2 ">
                        <div className="col">
                            <img src={logo1} alt="" className='img-fluid pe-2' />
                        </div>
                        <div className="col">
                            <img src={logo2} alt="" className='img-fluid pe-2' />
                        </div>
                        <div className="col">
                            <img src={logo3} alt="" className='img-fluid pe-2' />
                        </div>
                        <div className="col">
                            <img src={logo4} alt="" className='img-fluid pe-2' />
                        </div>
                        <div className="col">
                            <img src={logo5} alt="" className='img-fluid pe-2' />
                        </div>

                    </div>
                </div> */}

            </div>
        </div >
    );
};

export default DanapuriNetwork;