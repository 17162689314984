import React from 'react';
import { Accordion } from 'react-bootstrap';
import map from "../../../images/map-danapuri.svg";
import ArchiveNewNewsCard from '../ArchiveNewNewsCard/ArchiveNewNewsCard';
import './ArchivesCountryCard.css';

const ArchivesCountryCard = ({ news }) => {
    return (
        <div className="col m-0">
            <div className=" archives-country-card h-100">
                <img src={news?.image_url} alt="" className='img-fluid pb-3' />
                <Accordion>
                    <Accordion.Item eventKey={news?.id}>
                        <Accordion.Header >
                            <h1 className='archives-country-title'>{news?.title}</h1>
                        </Accordion.Header>
                        <Accordion.Body className='p-1'>
                            {news?.project_country_details_list?.map((d_news) => <ArchiveNewNewsCard
                                key={d_news.id}
                                d_news={d_news}
                            ></ArchiveNewNewsCard>)}
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            </div>

        </div>
    );
};

export default ArchivesCountryCard;