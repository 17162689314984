import React, { useEffect, useState } from 'react';
import { Triangle } from 'react-loader-spinner';
import BusinessContact from '../Shared/BusinessContact/BusinessContact';
import Footer from '../Shared/Footer/Footer';
import Navigation from '../Shared/Navigation/Navigation';
import Accomplishments from './Accomplishments/Accomplishments';
import ContactHome from './ContactHome/ContactHome';
import CoreValues from './CoreValues/CoreValues';
import DanapuriArcivesHome from './DanapuriArcivesHome/DanapuriArcivesHome';
import DanapuriOffer from './DanapuriOffer/DanapuriOffer';
import DigitalEconomy from './DigitalEconomy/DigitalEconomy';
import HomeBanner from './HomeBanner/HomeBanner';
import HomeTopNews from './HomeTopNews/HomeTopNews';
import WhyDanapuri from './WhyDanapuri/WhyDanapuri';

const Home = () => {

    const [homeInfo, setHomeInfo] = useState([]);

    //is loading state
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        setIsLoading(true);
        fetch('https://danapuristaging.techsistltd.com/home_details/')
            .then(res => res.json())
            .then(data => {
                setHomeInfo(data.data)
            })
            .finally(() => setIsLoading(false))
    }, [])
    if (isLoading) {
        return <div className="d-flex align-items-center justify-content-center vh-100" >
            <Triangle ariaLabel="loading-indicator" />
            Please wait...
        </div>
    }
    return (
        <div>
            <Navigation homeInfo={homeInfo}></Navigation>
            <HomeBanner homeInfo={homeInfo}></HomeBanner>
            <CoreValues homeInfo={homeInfo}></CoreValues>
            <WhyDanapuri homeInfo={homeInfo}></WhyDanapuri>
            <DanapuriOffer homeInfo={homeInfo}></DanapuriOffer>
            {/* <Accomplishments homeInfo={homeInfo}></Accomplishments> */}
            <DigitalEconomy homeInfo={homeInfo}></DigitalEconomy>
            <HomeTopNews homeInfo={homeInfo}></HomeTopNews>
            <BusinessContact></BusinessContact>
            {/* <DanapuriArcivesHome homeInfo={homeInfo}></DanapuriArcivesHome> */}
            {/* <ContactHome homeInfo={homeInfo}></ContactHome> */}
            <Footer></Footer>

        </div>
    );
};

export default Home;